import { FC } from "react";
import MuiMenu from "@mui/material/Menu";
import type { MenuProps } from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import type { MenuItemProps } from "@mui/material/MenuItem";

const Menu: FC<MenuProps> = ({ children, ...props }) => {
  return <MuiMenu {...props}>{children}</MuiMenu>;
};

const MenuItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return <MuiMenuItem {...props}>{children}</MuiMenuItem>;
};

export { Menu, MenuItem };
export type { MenuProps, MenuItemProps };
