import { FC } from "react";
import type { IconProps as MuiIconProps } from "@mui/material/Icon";
import { default as MuiIcon } from "@mui/material/Icon";
import { makeStyles } from "tss-react/mui";

const iconNames = [
  "report_problem",
  "star",
  "close",
  "account_circle",
  "menu",
  "shopping_cart",
  "volume_up",
  "chevron_right",
  "chevron_left",
  "location_on",
  "arrow_right",
  "arrow_left",
  "arrow_drop_up",
  "arrow_drop_down",
  "volume_off",
  "volume_mute",
  "expand_more",
  "expand_less",
  "check_circle",
  "info",
  "warning",
  "error",
  "add",
  "remove",
  "control_point",
  "fullscreen",
  "pause",
  "image",
  "play_arrow",
  "collections",
  "campaign",
  "gavel",
  "visibility",
  "visibility_off",
  "engineering",
  "check_box_outline_blank",
  "check_box",
  "person",
  "manage_accounts",
  "launch",
  "brightness_1",
  "play_circle",
  "stop",
  "replay",
  "more_vert",
  "edit",
  "search",
  "airplay",
  "send",
  "email",
  "dvr",
  "volume_up",
  "volume_off",
  "videocam",
  "photo",
  "done",
  "filter_list",
  "bookmark",
  "radio_button_checked",
  "radio_button_unchecked",
  "speaker_notes",
] as const;

type IconName = typeof iconNames[number];

interface IconProps extends MuiIconProps {
  icon: IconName | React.ReactNode;
  button?: boolean;
}

const useStyles = makeStyles()(() => ({
  button: {
    cursor: "pointer",
  },
}));

const Icon: FC<IconProps> = ({ icon, className, button = false, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <MuiIcon className={cx(className, button && classes.button)} {...props}>
      {icon}
    </MuiIcon>
  );
};

export { Icon, iconNames };
export type { IconProps, IconName };
