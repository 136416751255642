import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../app/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from "./useAttend";
export * from "./useAttendees";
export * from "./useAvPublishers";
export * from "./useAvStreams";
export * from "./useCompanies";
export * from "./useEvents";
export * from "./useLoggedInAttendeeCounts";
export * from "./useLogin";
export * from "./usePortStatus";
export * from "./usePreviewFlavors";
export * from "./useRequiresPermissions";
export * from "./useSetAvPublisherAction";
export * from "./useSetAvPublisherGain";
export * from "./useUpdateEventStatus";
export * from "./useUpdatePortStatus";
export * from "./useUserDetails";
export * from "./useFacilities";
export * from "./useChangeAvConnection";
export * from "./useCustomerSalesLinks";
export * from "./useCustomerAttend";
export * from "./useCumulativeStats";
export * from "./useSaleTitleAsWindowTitle";
export * from "./useMultiAttend";
