import { FC, forwardRef } from "react";
import { default as MuiAlert } from "@mui/material/Alert";
import type { AlertProps } from "@mui/material/Alert";

export const Alert: FC<AlertProps> = forwardRef(({ children, ...props }, ref) => {
  return (
    <MuiAlert {...props} ref={ref}>
      {children}
    </MuiAlert>
  );
});

export type { AlertProps };
