import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  discoveryServiceReducerPath,
  discoveryServiceReducer,
  discoveryServiceMiddleware,
  notificationsPath,
  notificationsReducer,
} from "@xcira/commons";
import { errorHandler } from "middleware";
import {
  cloudServicesReducerPath,
  cloudServicesReducer,
  cloudServicesMiddleware,
} from "services/cloud";
import {
  attendeesReducer,
  authReducer,
  dialogsReducer,
  eventsReducer,
  facilitiesReducer,
} from "slices";
import { cumulativeStatsReducer } from "slices/cumulative-stats";

const reducers = combineReducers({
  attendees: attendeesReducer,
  auth: authReducer,
  dialogs: dialogsReducer,
  events: eventsReducer,
  facilities: facilitiesReducer,
  cumulativeStats: cumulativeStatsReducer,
  [notificationsPath]: notificationsReducer,
  [discoveryServiceReducerPath]: discoveryServiceReducer,
  [cloudServicesReducerPath]: cloudServicesReducer,
});

const persistConfig = {
  key: "olr-tools",
  storage,
  whitelist: ["attendees", "auth", "events", "facilities"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(errorHandler.middleware)
      .concat([cloudServicesMiddleware, discoveryServiceMiddleware]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
