import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  makeStyles,
} from "@xcira/components";
import { ChangeEvent, FC, useState } from "react";
import { ExtractDialogProps, closeDialog } from "slices";
import { useSelectCustomerSalesLinkByFacilityName } from "hooks/useCustomerSalesLinks/useSelectCustomerSalesLinkByFacilityName";
import { useAppDispatch, useCustomerAttend } from "hooks";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  inputIcon: {
    color: theme.palette.grey[500],
  },
  dialogActions: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
}));

type DialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"CustomerAttend">;

const CustomerAttendDialog: FC<DialogProps> = ({ isOpen, facilityName, ahco, type }) => {
  const { classes } = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useAppDispatch();

  const customerSalesLink = useSelectCustomerSalesLinkByFacilityName(ahco, facilityName);

  const customerAttend = useCustomerAttend(
    ahco,
    customerSalesLink?.eventId ?? 0,
    type,
    username,
    password
  );

  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter" && !isDisabled) {
      handleSubmit();
    }
  };

  const isDisabled = !(username?.length && password?.length);

  const handleSubmit = async () => {
    await customerAttend();
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpen} data-testid="customerAttendDialog">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="customerAttendDialogTitle">
          Launch {type} for {customerSalesLink?.description}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input id="username" value={username} onChange={handleUserChange} />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={classes.inputIcon}
                >
                  <Icon icon={showPassword ? "visibility" : "visibility_off"}></Icon>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          autoFocus
          data-testid="portsConfirmationDialogCancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isDisabled}
          data-testid="portsConfirmationDialogOkButton"
        >
          LAUNCH
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CustomerAttendDialog };
